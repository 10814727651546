/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// $primary: #25408f;
// $secondary: #8f5325;
// $success: #3e8d63;
// $info: #13101c;
// $warning: #945707;
// $danger: #d62518;
// $light: #f8f9fa;
// $dark: #343a40;
// $theme-colors: (
//   primary: $primary,
//   secondary: $secondary,
//   success: $success,
//   info: $info,
//   warning: $warning,
//   danger: $danger,
//   light: $light,
//   dark: $dark,
//   // add any additional color below
// );
// $primary: red;

@font-face {
  font-family: mark;
  font-weight: 100;
  font-style: normal;
  src: url(assets/fonts/MarkLight.otf) format("opentype");
}
@font-face {
  font-family: mark;
  font-weight: 300;
  font-style: normal;
  src: url(assets/fonts/MarkRegular.otf) format("opentype");
}
@font-face {
  font-family: mark;
  font-weight: 400;
  font-style: normal;
  src: url(assets/fonts/MarkBook.otf) format("opentype");
}
@font-face {
  font-family: mark;
  font-weight: 700;
  font-style: normal;
  src: url(assets/fonts/MarkBold.otf) format("opentype");
}
@import "~intl-tel-input/build/css/intlTelInput.min.css";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$primary: #252dff;
$secondary: #071333;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

@import "node_modules/bootstrap/scss/bootstrap";

:root {
  --bs-primary: #252dff;
  --bs-primary-color: #252dff;
  --bs-primary-bg: #252dff;
  --bs-primary-rgb: 37, 45, 255;
  --bs-secondary: #071333;
  --bs-secondary-color: #071333;
  --bs-secondary-bg: #071333;
  --bs-secondary-rgb: 7, 19, 51;
  --ck-z-modal: calc(var(--ck-z-default) + 1055) !important;

}

app-loader {
  display: block;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  width: 100vw;
}

body {
  background-color: #fff !important;
  * {
    font-family: "mark" !important;
  }

  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05),
      0px 20px 50px rgba(0, 0, 0, 0.15);
  }

  .ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
    background-color: #252dff;
    color: #ffff;
    width: 138px;
    cursor: pointer;
  }

  .ck.ck-list__item .ck-button.ck-on {
    background-color: transparent;
    width: 138px;
    color: #071333;
    font-weight: 400;
  }

  .ck.ck-list__item .ck-button {
    color: #071333;
    font-weight: 400;
  }

  .ck-rounded-corners
    .ck.ck-dropdown
    .ck-dropdown__panel
    .ck-list
    .ck-list__item:last-child
    .ck-button {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 138px;
  }

  .ck-rounded-corners
    .ck.ck-dropdown
    .ck-dropdown__panel
    .ck-list
    .ck-list__item:first-child
    .ck-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list {
    border-radius: 9px;
    width: 10.6em;
  }

  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    p {
      color: #071333;
    }
  }

  .ck-rounded-corners .ck.ck-toolbar {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f0f0f0;
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #f0f0f0;
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    background-color: #f0f0f0;
  }

  .ck-editor__editable_inline {
    min-height: 150px;
  }

  .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__panel .ck-list__item {
    min-width: 100%;
  }
}
:root {
  .ngx-pagination .current,
  .form-check-input:checked {
    background-color: $primary !important;
  }
}

.paginator-style {
  padding: 1.3em;
}

.form-inline .form-control {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.thumb {
  height: 5em;
  max-width: 100px;
  border-radius: 0.62em;
  object-fit: contain;
}
.thumb-delete {
  position: relative;
  z-index: 1;
  cursor: pointer;
  &:hover:before {
    content: "\f5de";
    border-radius: 0.5em;
    font-family: "bootstrap-icons" !important;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    font-size: 2em;
    z-index: 10;
  }
}
.nav-link {
  margin-right: 0.25em;
  &:not(.active) {
    background-color: #f0f0f0 !important;
    color: #666;
  }
}
.form-group {
  margin-bottom: 0.5em;
}
// table style
.table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 8px;
  // table header style
  thead th {
    font-weight: bold;
    background-color: #fff;
    border-bottom: none;
    text-align: left;
    &.sortable:after {
      position: relative;
      top: 2px;
      cursor: pointer;
      content: url('./assets/icon/arrows.svg');
      font-size: 0.75rem;
      margin-left: 0.5rem;
    }
    &.asc {
      &:after {
        position: relative;
        top: 6px;
        content: url('./assets/icon/asc-arrow.svg');
      }
    }
    &.desc {
      &:after {
        position: relative;
        top: -1px;
        content: url('./assets/icon/desc-arrow.svg');
      }
    }
  }

  // table cell style
  td,
  th {
    padding: 1rem;
    vertical-align: middle;
    border-top: 1px solid #f2f2f2;
  }
}

// primary button style
.btn-primary {
  // background-color: #3d4852;
  // border-color: #3d4852;
  border-radius: 8px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    // background-color: #2d3741;
    // border-color: #2d3741;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px -4px rgba(61, 72, 82, 0.6);
  }
}

// secondary button style
.btn-secondary {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  border-radius: 8px;
  color: #3d4852;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #3d4852;
    color: #3d4852;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px -4px rgba(61, 72, 82, 0.6);
  }
}

// modal overlay style
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// modal content style
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

// modal header style
.modal-header {
  border-bottom: none;
  background-color: #f9f9f9;
  border-radius: 8px 8px 0 0;
  .close {
    color: #000;
  }
}

// modal footer style
.modal-footer {
  border-top: none;
  background-color: #f9f9f9;
  border-radius: 0 0 8px 8px;
}

// input style
.form-control {
  border-radius: 8px !important;
  border-color: #f2f2f2 !important;
  box-shadow: none !important;
  &:focus {
    border-color: #3d4852 !important;
    box-shadow: 0 0 0 0.1rem rgba(61, 72, 82, 0.25) !important;
  }
}

// label style
.form-label {
  font-weight: bold !important;
  color: #3d4852 !important;
}

// input group style
.input-group {
  margin-bottom: 1rem !important;
}

// input group addon style
.input-group-addon {
  background-color: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  border-radius: 8px !important;
}
